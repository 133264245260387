(function() {
    'use strict';

    angular
        .module('easyflowApp')
        .controller('ElementoDialogController', ElementoDialogController);

    ElementoDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'Elemento', 'Diagrama', 'Funcionalidade', 'PropriedadeElemento', 'Conexao'];

    function ElementoDialogController ($scope, $stateParams, $uibModalInstance, entity, Elemento, Diagrama, Funcionalidade, PropriedadeElemento, Conexao) {
        var vm = this;
        vm.elemento = entity;
        vm.diagramas = Diagrama.query();
        vm.funcionalidades = Funcionalidade.query({size: 150});
        vm.propriedadeelementos = PropriedadeElemento.query();
        vm.conexaos = Conexao.query();
        vm.load = function(id) {
            Elemento.get({id : id}, function(result) {
                vm.elemento = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('easyflowApp:elementoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.elemento.id !== null) {
                Elemento.update(vm.elemento, onSaveSuccess, onSaveError);
            } else {
                Elemento.save(vm.elemento, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
