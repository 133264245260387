(function() {
    'use strict';

    angular
        .module('easyflowApp')
        .controller('SistemaDeleteController',SistemaDeleteController);

    SistemaDeleteController.$inject = ['$uibModalInstance', 'entity', 'Sistema'];

    function SistemaDeleteController($uibModalInstance, entity, Sistema) {
        var vm = this;
        vm.sistema = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            Sistema.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
