(function() {
    'use strict';

    angular
        .module('easyflowApp')
        .controller('SistemaDialogController', SistemaDialogController);

    SistemaDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'Sistema', 'User'];

    function SistemaDialogController ($scope, $stateParams, $uibModalInstance, entity, Sistema, User) {
        var vm = this;
        vm.sistema = entity;
        vm.users = User.query();
        vm.load = function(id) {
            Sistema.get({id : id}, function(result) {
                vm.sistema = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('easyflowApp:sistemaUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.sistema.id !== null) {
                Sistema.update(vm.sistema, onSaveSuccess, onSaveError);
            } else {
                Sistema.save(vm.sistema, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dataCriacao = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
