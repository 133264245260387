(function() {
    'use strict';

    angular
        .module('easyflowApp')
        .controller('RestricaoDeleteController',RestricaoDeleteController);

    RestricaoDeleteController.$inject = ['$uibModalInstance', 'entity', 'Restricao'];

    function RestricaoDeleteController($uibModalInstance, entity, Restricao) {
        var vm = this;
        vm.restricao = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            Restricao.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
