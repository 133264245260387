(function() {
    'use strict';

    angular
        .module('easyflowApp')
        .controller('CenarioDialogController', CenarioDialogController);

    CenarioDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Cenario', 'Elemento', 'PropriedadeElemento'];

    function CenarioDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Cenario, Elemento, PropriedadeElemento) {
        var vm = this;
        vm.cenario = entity;
        vm.elementos = Elemento.query();
        vm.propriedadeelementos = PropriedadeElemento.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('easyflowApp:cenarioUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.cenario.id !== null) {
                Cenario.update(vm.cenario, onSaveSuccess, onSaveError);
            } else {
                Cenario.save(vm.cenario, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
