(function() {
    'use strict';

    angular
        .module('easyflowApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('diagrama', {
            parent: 'entity',
            url: '/diagrama?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'easyflowApp.diagrama.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/diagrama/diagramas.html',
                    controller: 'DiagramaController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('diagrama');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('diagrama-detail', {
            parent: 'entity',
            url: '/diagrama/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'easyflowApp.diagrama.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/diagrama/diagrama-detail.html',
                    controller: 'DiagramaDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('diagrama');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Diagrama', function($stateParams, Diagrama) {
                    return Diagrama.get({id : $stateParams.id}).$promise;
                }]
            }
        })
//        .state('diagrama.new', {
//            parent: 'diagrama',
//            url: '/new',
//            data: {
//                authorities: ['ROLE_USER']
//            },
//            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
//                $uibModal.open({
//                    templateUrl: 'app/entities/diagrama/diagrama-dialog.html',
//                    controller: 'DiagramaDialogController',
//                    controllerAs: 'vm',
//                    backdrop: 'static',
//                    size: 'lg',
//                    resolve: {
//                        entity: function () {
//                            return {
//                                xml: null,
//                                nome: null,
//                                ativo: false,
//                                dataCriacao: null,
//                                id: null
//                            };
//                        }
//                    }
//                }).result.then(function() {
//                    $state.go('diagrama', null, { reload: true });
//                }, function() {
//                    $state.go('diagrama');
//                });
//            }]
//        })
//        .state('diagrama.edit', {
//            parent: 'diagrama',
//            url: '/{id}/edit',
//            data: {
//                authorities: ['ROLE_USER']
//            },
//            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
//                $uibModal.open({
//                    templateUrl: 'app/entities/diagrama/diagrama-dialog.html',
//                    controller: 'DiagramaDialogController',
//                    controllerAs: 'vm',
//                    backdrop: 'static',
//                    size: 'lg',
//                    resolve: {
//                        entity: ['Diagrama', function(Diagrama) {
//                            return Diagrama.get({id : $stateParams.id});
//                        }]
//                    }
//                }).result.then(function() {
//                    $state.go('diagrama', null, { reload: true });
//                }, function() {
//                    $state.go('^');
//                });
//            }]
//        })
        .state('diagrama.delete', {
            parent: 'diagrama',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/diagrama/diagrama-delete-dialog.html',
                    controller: 'DiagramaDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Diagrama', function(Diagrama) {
                            return Diagrama.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('diagrama', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('diagrama.new', {
            parent: 'entity',
            url: '/diagrama/new',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'easyflowApp.diagrama.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/diagrama/diagrama-new.html',
                    controller: 'DiagramaNewController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('diagrama');
                    return $translate.refresh();
                }],
                newDiagramXml: ['$http', function($http) {
                	// tive que resolver a promise antes de abrir a tela.
                	// pois quando a tela abre o bpmn.io já precisa do xml
                	return $http.get('content/newDiagram.bpmn').then(function(data){
                		return data.data;
                	});
                }],
                entity: ['$stateParams', 'Diagrama', function($stateParams, Diagrama) {
                	return {
                		id: null,
                		ativo: true,
                		elementos: [],
                		conexoes: []
                	};
                }]
            }
        })
        .state('diagrama.edit', {
            parent: 'diagrama',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/diagrama/diagrama-new.html',
                    controller: 'DiagramaNewController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('diagrama');
                    $translatePartialLoader.addPart('elemento');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Diagrama', function($stateParams, Diagrama) {
                	// tive que resolver a promise antes de abrir a tela.
                	// pois quando a tela abre o bpmn.io já precisa do xml
                    return Diagrama.get({id : $stateParams.id}).$promise.then(function(data){
                    	return data;
                    });
                }],
                newDiagramXml: [function(){
                	return null
                }]
            }
        });
//        .state('diagrama.edit.tarefa', {
//            url: '/tarefa',
//            parent: 'diagrama.edit',
//            params: {
//                'elementId': 0,
//                'diagrama': null,
//            },
//            data: {
//                authorities: ['ROLE_USER']
//            },
//            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
//
//                // previne refresh nesse 'state'. Envia o fluxo pro state inicial.
//                if($stateParams.diagrama == undefined) {
//                    $state.go('diagrama.edit', {id: $stateParams.id});
//                    return;
//                }
//
//                $uibModal.open({
//                    templateUrl: 'app/entities/elemento/diagrama-elemento-propriedade-dialog.html',
//                    controller: 'DiagramaTarefaController',
//                    controllerAs: 'vm',
//                    size: 'md',
//                    resolve: {
//                        elemento: ['Elemento', function(Elemento) {
//                            if ($stateParams.diagrama.id > 0) {
//                                return Elemento.getBpmnElementId({bpmnElementId: $stateParams.elementId, diagramaId: $stateParams.diagrama.id});
//                            } else {
//                                return {elementId: elementId};
//                            }
//                        }],
//                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
//                            $translatePartialLoader.addPart('elemento');
//                            $translatePartialLoader.addPart('funcionalidade');
//                            $translatePartialLoader.addPart('propriedade');
//                            $translatePartialLoader.addPart('global');
//                            $translatePartialLoader.addPart('propriedadeElemento');
//                            return $translate.refresh();
//                        }]
//                    }
//                }).result.then(function() {
//                    $state.go('^', null, { reload: false });
//                }, function() {
//                    $state.go('^');
//                });
//            }],
//        });
    }

})();
