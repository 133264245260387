(function() {
    'use strict';

    angular
        .module('easyflowApp')
        .controller('PropriedadeElementoDetailController', PropriedadeElementoDetailController);

    PropriedadeElementoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PropriedadeElemento', 'Elemento', 'Propriedade', 'Restricao'];

    function PropriedadeElementoDetailController($scope, $rootScope, $stateParams, entity, PropriedadeElemento, Elemento, Propriedade, Restricao) {
        var vm = this;
        vm.propriedadeElemento = entity;
        
        var unsubscribe = $rootScope.$on('easyflowApp:propriedadeElementoUpdate', function(event, result) {
            vm.propriedadeElemento = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
