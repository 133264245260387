(function() {
    'use strict';

    angular
        .module('easyflowApp')
        .controller('RestricaoDetailController', RestricaoDetailController);

    RestricaoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Restricao', 'PropriedadeElemento', 'Elemento'];

    function RestricaoDetailController($scope, $rootScope, $stateParams, entity, Restricao, PropriedadeElemento, Elemento) {
        var vm = this;
        vm.restricao = entity;
        
        var unsubscribe = $rootScope.$on('easyflowApp:restricaoUpdate', function(event, result) {
            vm.restricao = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
