(function() {
    'use strict';

    angular
        .module('easyflowApp')
        .controller('CenarioDetailController', CenarioDetailController);

    CenarioDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Cenario', 'Elemento', 'PropriedadeElemento'];

    function CenarioDetailController($scope, $rootScope, $stateParams, entity, Cenario, Elemento, PropriedadeElemento) {
        var vm = this;
        vm.cenario = entity;
        
        var unsubscribe = $rootScope.$on('easyflowApp:cenarioUpdate', function(event, result) {
            vm.cenario = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
