(function() {
    'use strict';

    angular
        .module('easyflowApp')
        .controller('ConexaoDetailController', ConexaoDetailController);

    ConexaoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Conexao', 'Elemento'];

    function ConexaoDetailController($scope, $rootScope, $stateParams, entity, Conexao, Elemento) {
        var vm = this;
        vm.conexao = entity;
        vm.load = function (id) {
            Conexao.get({id: id}, function(result) {
                vm.conexao = result;
            });
        };
        var unsubscribe = $rootScope.$on('easyflowApp:conexaoUpdate', function(event, result) {
            vm.conexao = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
