angular.forEach(function() {
    'use strict';

    angular
        .module('easyflowApp')
        .controller('DiagramaTarefaController',DiagramaTarefaController);

    DiagramaTarefaController.$inject = ['$timeout', '$uibModalInstance', '$stateParams', 'Funcionalidade', 'diagrama', 'element', 'elemento',
    'Propriedade', 'AlertService'];

    function DiagramaTarefaController($timeout, $uibModalInstance, $stateParams, Funcionalidade, diagrama, element, elemento, Propriedade,
    AlertService) {
        var vm = this;
        vm.funcionalidades = Funcionalidade.query({size: 150});
        vm.diagrama = diagrama;
        vm.elemento = elemento;

        /**
         * Atualiza o json do elemento sempre que o usuário alterar a combo de funcionalidade
         **/
        vm.onFuncionalidadeChange = function() {

        };

        vm.adicionarPropriedadeElementos = function(cenario) {
            if (vm.elemento.funcionalidadeId > 0) {
                Propriedade.listarPorFuncionalidade({idFuncionalidade: vm.elemento.funcionalidadeId}, function(data){
                    var propriedadeElementos = [];
                    angular.forEach(data, function(value, key){
                        var propriedadeElemento = {
                            propriedadeId: value.id,
                            propriedadeNome: value.nome,
                            tipoDePropriedade: value.tipoDePropriedade
                        };
                        propriedadeElementos.push(propriedadeElemento);
                    });
                    cenario.propriedadeElementos = propriedadeElementos;
                });
            }
        };

        /**
        * Atualiza o json de restrição sempre que o usuário alterar a saída (próximo elemento)
        **/
        vm.onTransicaoDeSaidaChange = function(cenario, bpmnElementId) {
            vm.diagrama.elementos.forEach(function(elemento) {
                if (elemento.bpmnElementId == bpmnElementId) {
                    cenario.transicaoDeSaidaId = elemento.id;
                    cenario.transicaoDeSaidaBpmnElementId = elemento.bpmnElementId;
                    cenario.transicaoDeSaidaNome = elemento.nome;
                }
            });
        };

        /**
        * Retorna os próximo elementos ligados ao elemento selecionado
        **/
        vm.getProximosElementos = function(elemento, transicaoDeSaidaBpmnElementIdAtual) {
            //var bpmnElement = elementRegistry.get(element.bpmnElementId);
            // var outgoing = bpmnElement.outgoing;
            var outgoings = element.outgoing;
            var proximosElementos = [];

            outgoings.forEach(function(itemSaida){
                vm.diagrama.elementos.forEach(function(itemElemento){
                    if (itemSaida.target.id == itemElemento.bpmnElementId) {
                        proximosElementos.push(itemElemento);
                    }
                })
            })

            if (proximosElementos.length == 0) {
                // adiciona a próxima saída atualmente cadastrada
                vm.diagrama.elementos.forEach(function(itemElemento) {
                    if (itemElemento.bpmnElementId == transicaoDeSaidaBpmnElementIdAtual) {
                        proximosElementos.push(itemElemento);
                    }
                });
            }

            return proximosElementos;
        }

        /**
        * Determina se as propriedades podem ser editadas.
        * Se o usuário clicar em um elemento que é seguido de um gateway, então não será possível editar propriedades.
        *   Neste caso o usuário precisar clicar no gateway para editar as propriedades.
        **/
        vm.bloquearEdicao = function() {
            if (element.outgoing.length > 0 && element.outgoing[0].target.type == "bpmn:ExclusiveGateway") {
                return true;
            }
            return false;
        };

        vm.editarLinha = function(cenario, propriedade) {
            vm.propriedadeSelecionadaOriginal = propriedade;
            vm.propriedadeSelecionada = angular.copy(propriedade);
            vm.cenarioSelecionado = cenario;
            if (vm.propriedadeSelecionada.restricoes == undefined || vm.propriedadeSelecionada.restricoes.length == 0) {
                vm.propriedadeSelecionada.restricoes = [];
                vm.adicionarRestricao();
            }
        };

        vm.getTemplate = function(propriedade) {
            if (vm.propriedadeSelecionada && vm.propriedadeSelecionada.propriedadeId == propriedade.propriedadeId) {
                return 'edit';
            }
            return 'display';
        };

        vm.resetPropriedadeSelecionada = function() {
            vm.propriedadeSelecionada = undefined;
            vm.cenarioSelecionado = undefined;
            vm.propriedadeSelecionadaOriginal = undefined;
        };

        vm.removerRestricao = function(propriedadeSelecionada, restricao) {
            var index = vm.propriedadeSelecionada.restricoes.indexOf(restricao);
            vm.propriedadeSelecionada.restricoes.splice(index, 1);
        };

        vm.adicionarRestricao = function(idx) {
            var restricao = {};
            if (vm.propriedadeSelecionada.tipoDePropriedade == 'BOOLEAN') {
                restricao.valoresParaComparacao = "false";
            }
            vm.propriedadeSelecionada.restricoes.push(restricao);
        };

        vm.salvarPropriedadeSelecionada = function() {
            var mensagemErro = '';
            angular.forEach(vm.propriedadeSelecionada.restricoes, function(restricao){
                if (restricao.valoresParaComparacao == undefined) {
                    mensagemErro = mensagemErro + 'A condição não pode ser vazia.<br>';
                }
                if (restricao.tipoDeOperador == undefined) {
                    mensagemErro = mensagemErro + 'Informe tipo de operador.';
                }
            });
            if (mensagemErro != '') {
                AlertService.error(mensagemErro);
                return;
            }
            var index = vm.cenarioSelecionado.propriedadeElementos.indexOf(vm.propriedadeSelecionadaOriginal);
            vm.cenarioSelecionado.propriedadeElementos[index] = angular.copy(vm.propriedadeSelecionada);
            vm.resetPropriedadeSelecionada();
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.salvar = function (id) {
            $uibModalInstance.close(vm.elemento);
        };

        vm.adicionarCenario = function() {
            var cenario = { propriedadeElementos: [] };
            vm.adicionarPropriedadeElementos(cenario);
            vm.elemento.cenarios.push(cenario);
            $timeout(function() {
                vm.tabActive = vm.elemento.cenarios.length;
            }, 20);
        }

        vm.removerCenario = function(cenario) {
            if ( window.confirm("Deseja remover o cenário?") ) {
                var index = vm.elemento.cenarios.indexOf(cenario);
                if (index > -1) {
                    vm.elemento.cenarios.splice(index, 1);
                }
            }

        }
    }
})();
