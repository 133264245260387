(function() {
    'use strict';

    angular
        .module('easyflowApp')
        .controller('DiagramaDeleteController',DiagramaDeleteController);

    DiagramaDeleteController.$inject = ['$uibModalInstance', 'entity', 'Diagrama'];

    function DiagramaDeleteController($uibModalInstance, entity, Diagrama) {
        var vm = this;
        vm.diagrama = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            Diagrama.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
