(function() {
    'use strict';

    angular
        .module('easyflowApp')
        .controller('PropriedadeElementoDeleteController',PropriedadeElementoDeleteController);

    PropriedadeElementoDeleteController.$inject = ['$uibModalInstance', 'entity', 'PropriedadeElemento'];

    function PropriedadeElementoDeleteController($uibModalInstance, entity, PropriedadeElemento) {
        var vm = this;
        vm.propriedadeElemento = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            PropriedadeElemento.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
