(function() {
    'use strict';

    angular
        .module('easyflowApp')
        .controller('DiagramaController', DiagramaController);

    DiagramaController.$inject = ['$scope', '$state', 'DataUtils', 'Diagrama', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

    function DiagramaController ($scope, $state, DataUtils, Diagrama, ParseLinks, AlertService, pagingParams, paginationConstants) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;
        vm.loadAll();

        function loadAll () {
            Diagrama.query({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.diagramas = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        vm.clone = function (id) {
            Diagrama.clone({'id':id}, function (){
                loadAll();
            }, function(error) {
                AlertService.error(error.data.message);
            })
        }
        
        vm.versionar = function (id) {
        	Diagrama.versionar({'id':id}, function (){
        		loadAll();
        	}, function(error) {
        		AlertService.error(error.data.message);
        	})
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

    }
})();
