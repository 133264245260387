(function() {
    'use strict';

    angular
        .module('easyflowApp')
        .controller('ElementoDetailController', ElementoDetailController);

    ElementoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Elemento', 'Diagrama', 'Funcionalidade', 'PropriedadeElemento', 'Conexao'];

    function ElementoDetailController($scope, $rootScope, $stateParams, entity, Elemento, Diagrama, Funcionalidade, PropriedadeElemento, Conexao) {
        var vm = this;
        vm.elemento = entity;
        vm.load = function (id) {
            Elemento.get({id: id}, function(result) {
                vm.elemento = result;
            });
        };
        var unsubscribe = $rootScope.$on('easyflowApp:elementoUpdate', function(event, result) {
            vm.elemento = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
