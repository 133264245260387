(function() {
    'use strict';

    angular
        .module('easyflowApp')
        .controller('DiagramaDetailController', DiagramaDetailController);

    DiagramaDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'DataUtils', 'entity', 'Diagrama', 'User', 'Sistema', '$window'];

    function DiagramaDetailController($scope, $rootScope, $stateParams, DataUtils, entity, Diagrama, User, Sistema, $window) {

        var vm = this;
        vm.diagrama = entity;

        var unsubscribe = $rootScope.$on('easyflowApp:diagramaUpdate', function(event, result) {
            vm.diagrama = result;
        });
        $scope.$on('$destroy', unsubscribe);

        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var BpmnViewer = window.BpmnJS;
        var viewer = new BpmnViewer({
            container: '#canvasView',
            height: 400
          });

        var carregarXml = function(xml) {
        	if (xml == undefined) {
        		return;
        	}
        	viewer.importXML(xml, function(err) {
        		if (err) {
        			console.error('failed to load diagram');
        			console.error(err);

        			return log('failed to load diagram', err);
        		}});
        };

        carregarXml(vm.diagrama.xml);

    }
})();
