(function() {
    'use strict';

    angular
        .module('easyflowApp')
        .controller('PropriedadeDialogController', PropriedadeDialogController);

    PropriedadeDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'Propriedade', 'Funcionalidade'];

    function PropriedadeDialogController ($scope, $stateParams, $uibModalInstance, entity, Propriedade, Funcionalidade) {
        var vm = this;
        vm.propriedade = entity;
        vm.funcionalidades = Funcionalidade.query({size: 150});
        vm.load = function(id) {
            Propriedade.get({id : id}, function(result) {
                vm.propriedade = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('easyflowApp:propriedadeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.propriedade.id !== null) {
                Propriedade.update(vm.propriedade, onSaveSuccess, onSaveError);
            } else {
                Propriedade.save(vm.propriedade, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
