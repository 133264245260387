(function() {
    'use strict';

    angular
        .module('easyflowApp')
        .controller('DiagramaNewController', DiagramaDetailController);

    DiagramaDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Diagrama', '$window', '$state', 'newDiagramXml',
        'AlertService', '$uibModal'];

    function DiagramaDetailController($scope, $rootScope, $stateParams, entity, Diagrama, window, $state, newDiagramXml, AlertService,
        $uibModal) {
        var BpmnModeler = window.BpmnJS;
        var bpmnModeler = new BpmnModeler({container: '#canvas'});
        var vm = this;
        vm.diagrama = entity;
        $scope.tabAtiva = 0;

        vm.save = function() {
        	// get the diagram contents
          bpmnModeler.saveXML({ format: true }, function(err, xml) {
            if (err) {
              console.error('diagram save failed', err);
            } else {
            	vm.saveDiagram(xml);
            }
          });

        };

        vm.saveDiagram = function (xml) {
            vm.isSaving = true;
            vm.diagrama.xml = xml;
            if (vm.diagrama.id !== null) {
                Diagrama.update(vm.diagrama, onSaveSuccess, onSaveError);
            } else {
                Diagrama.save(vm.diagrama, onSaveSuccess, onSaveError);
            }
        };

        var onSaveSuccess = function (result) {
            $state.go('diagrama', null, { reload: true });
            $scope.$emit('easyflowApp:diagramaUpdate', result);
            vm.isSaving = false;
        };

        var onSaveError = function (error) {
        	AlertService.error(error.data.message);
            vm.isSaving = false;
        };

        var carregarXml = function(xml) {
        	if (xml == undefined) {
        		return;
        	}
        	bpmnModeler.importXML(xml, function(err) {
        		if (err) {
        			return console.error('could not import BPMN 2.0 diagram', err);
        		}});
            var canvas = bpmnModeler.get('canvas');
            // zoom to fit full viewport
            canvas.zoom('fit-viewport');
        };

        // Adiciona listeners no diagrama
        // Exemplo de eventos:  'element.hover','element.out','element.click','element.dblclick','element.mousedown','element.mouseup'
        // diagram.clear, connect.move, selection.changed
        var eventBus = bpmnModeler.get('eventBus');

        // houve alteração no diagrama:
        eventBus.on('elements.changed', function(e){
            angular.forEach(e.elements, function(value, key){
                var elementoLocalizado = localizaElementoBpmnNoJson(value);
                if (elementoLocalizado != null) {
                    // atualiza o nome
                    elementoLocalizado.nome = value.businessObject.name;
                    //atualiza ligações se elemento for uma seta:
                    if (value.type == 'bpmn:SequenceFlow') {
                        elementoLocalizado.bpmnElementIdOrigem = value.source.id;
                        elementoLocalizado.bpmnElementIdDestino = value.target.id
                    }
                }
            });
        });

        // Elemento foi removido do diagrama:
        eventBus.on(['shape.remove', 'connection.remove'], function(e){
//            console.log(e);
            var elemento = localizaElementoBpmnNoJson(e.element);
            if (elemento == null) return;

            if (e.element.type == 'bpmn:SequenceFlow') {
                var indexOf = vm.diagrama.conexoes.indexOf(elemento);
                vm.diagrama.conexoes.splice(indexOf, 1);
            } else {
                var indexOf = vm.diagrama.elementos.indexOf(elemento);
                vm.diagrama.elementos.splice(indexOf, 1);
            }
        });

        // Elemento foi adicionado ao diagrama:
        eventBus.on(['shape.added', 'connection.added'], function(e){
            var element = e.element;
            // verifica se o elemento já existe como DTO
            var elementoLocalizado = localizaElementoBpmnNoJson(element);
            if (elementoLocalizado == null) {
                if (element.type.indexOf("Task") > -1) { // qualquer tipo de Task
                    vm.diagrama.elementos.push({bpmnElementId: element.id, tipoDeElemento: 'TASK', cenarios: []});
                } else if (element.type == 'bpmn:StartEvent') {
                    vm.diagrama.elementos.push({bpmnElementId: element.id, tipoDeElemento: 'START'});
                } else if (element.type == 'bpmn:EndEvent') {
                    vm.diagrama.elementos.push({bpmnElementId: element.id, tipoDeElemento: 'END'});
                } else if (element.type == 'bpmn:ExclusiveGateway') {
                    vm.diagrama.elementos.push({bpmnElementId: element.id, tipoDeElemento: 'GATEWAY'});
                } else if (element.type == 'bpmn:SequenceFlow') {
                    vm.diagrama.conexoes.push({bpmnElementId: element.id, bpmnElementIdOrigem: element.source.id, bpmnElementIdDestino: element
                     .target.id});
                }
            }
        });

        var localizaElementoBpmnNoJson = function(element) {
            var elementoLocalizado = null;
            var elementosBmpnIo = vm.diagrama.elementos;
            if (element.type == 'bpmn:SequenceFlow') {
                elementosBmpnIo = vm.diagrama.conexoes;
            }

            for (var i = 0, len = elementosBmpnIo.length; i < len; i++) {
                if (elementosBmpnIo[i].bpmnElementId == element.id) {
                    elementoLocalizado = elementosBmpnIo[i];
                    break;
                }
            }

            return elementoLocalizado ;
        };

        eventBus.on('element.dblclick', function(e){
            var element = e.element;
            if (element.type.indexOf("Task") > -1) { // qualquer tipo de Task
                abrirModal(element, element.id);
            } else if (element.type == 'bpmn:ExclusiveGateway' && element.incoming.length == 1) {
                abrirModal(element, element.incoming[0].source.id);
            }
        });

        // abre modal de propriedade-elemento
        var abrirModal = function(element, bpmnElementId) {
            var modalInstance = $uibModal.open({
                templateUrl: 'app/entities/elemento/diagrama-elemento-propriedade-dialog.html',
                controller: 'DiagramaTarefaController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    diagrama: [function(){ return vm.diagrama}],
                    element: element,
                    elemento: ['Elemento', function(Elemento) {

                        for (var i in vm.diagrama.elementos) {
                            if (vm.diagrama.elementos[i].bpmnElementId == bpmnElementId) {
                                return angular.copy(vm.diagrama.elementos[i]);
                            }
                        }
                        // se não achou o elemento é porque é novo
                        if (vm.diagrama.id > 0) {
                            return Elemento.getBpmnElementId({bpmnElementId: bpmnElementId, diagramaId: vm.diagrama.id});
                        } else {
                            return {bpmnElementId: bpmnElementId};
                        }
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('elemento');
                        $translatePartialLoader.addPart('funcionalidade');
                        $translatePartialLoader.addPart('propriedade');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('propriedadeElemento');
                        $translatePartialLoader.addPart('tipoDeOperador');
                        return $translate.refresh();
                    }]
                }
            })
            .result.then(function(elemento) {
                vm.atualizaElementoDoDiagrama(elemento);
            }, function() {
                // faz nada
            });

        };

        vm.atualizaElementoDoDiagrama = function(elemento) {
            if (vm.diagrama.elementos.length == 0) {
                vm.diagrama.elementos[0] = elemento;
                return;
            }

           for (var i in vm.diagrama.elementos) {
             if (vm.diagrama.elementos[i].bpmnElementId == elemento.bpmnElementId) {
                vm.diagrama.elementos[i] = elemento;
                break;
             }
           }
        };

        // quando invocado a tela de 'novo diagrama' a variável 'newDiagramXml' será injetada no controller
        // ela contém o valor do xml inicial para desenho
        $scope.carregar = function() {
        	if (entity.xml != undefined)
        		carregarXml(entity.xml);
        	else if (newDiagramXml != undefined)
        		carregarXml(newDiagramXml);
        };

        $scope.carregar();

    };

})();
