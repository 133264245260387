(function() {
    'use strict';
    angular
        .module('easyflowApp')
        .factory('Diagrama', Diagrama);

    Diagrama.$inject = ['$resource', 'DateUtils'];

    function Diagrama ($resource, DateUtils) {
        var resourceUrl =  'api/diagramas/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.dataCriacao = DateUtils.convertDateTimeFromServer(data.dataCriacao);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'clone': {method:'GET', url:'api/diagramas/clone/:id'},
            'versionar': {method:'GET', url:'api/diagramas/versionar/:id'}
        });
    }
})();
