(function() {
    'use strict';

    angular
        .module('easyflowApp')
        .controller('FuncionalidadeDetailController', FuncionalidadeDetailController);

    FuncionalidadeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Funcionalidade', 'Sistema', 'Propriedade'];

    function FuncionalidadeDetailController($scope, $rootScope, $stateParams, entity, Funcionalidade, Sistema, Propriedade) {
        var vm = this;
        vm.funcionalidade = entity;
        vm.load = function (id) {
            Funcionalidade.get({id: id}, function(result) {
                vm.funcionalidade = result;
            });
        };
        var unsubscribe = $rootScope.$on('easyflowApp:funcionalidadeUpdate', function(event, result) {
            vm.funcionalidade = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
