(function() {
    'use strict';

    angular
        .module('easyflowApp')
        .controller('DiagramaDialogController', DiagramaDialogController);

    DiagramaDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'Diagrama', 'User', 'Sistema'];

    function DiagramaDialogController ($scope, $stateParams, $uibModalInstance, DataUtils, entity, Diagrama, User, Sistema) {
        var vm = this;
        vm.diagrama = entity;
        vm.users = [];// User.query();
        vm.sistemas = []; //Sistema.query();
        vm.elementos = [];
        vm.load = function(id) {
            Diagrama.get({id : id}, function(result) {
                vm.diagrama = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('easyflowApp:diagramaUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.diagrama.id !== null) {
                Diagrama.update(vm.diagrama, onSaveSuccess, onSaveError);
            } else {
                Diagrama.save(vm.diagrama, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dataCriacao = false;

        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
