(function() {
    'use strict';

    angular
        .module('easyflowApp')
        .controller('PropriedadeElementoDialogController', PropriedadeElementoDialogController);

    PropriedadeElementoDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PropriedadeElemento', 'Elemento', 'Propriedade', 'Restricao'];

    function PropriedadeElementoDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, PropriedadeElemento, Elemento, Propriedade, Restricao) {
        var vm = this;
        vm.propriedadeElemento = entity;
        vm.elementos = Elemento.query();
        vm.propriedades = Propriedade.query();
        vm.restricaos = Restricao.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('easyflowApp:propriedadeElementoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.propriedadeElemento.id !== null) {
                PropriedadeElemento.update(vm.propriedadeElemento, onSaveSuccess, onSaveError);
            } else {
                PropriedadeElemento.save(vm.propriedadeElemento, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
